import axios from 'axios';
import { authorizedFetch } from './authorizedFetch';

export const PER_PAGE = 10;

export const getImages = async ({ queryKey }) => {
	const { simplesearch, page, chosenCategory, sortType } = queryKey[1];

	const response = await authorizedFetch(
		`${
			queryKey[0]
		}?page=${page}&itemsPerPage=${PER_PAGE}&simplesearch=${simplesearch}&order[createdAt]=${sortType}${
			chosenCategory ? `&imageGroup.categories.id=${chosenCategory}` : ''
		}`,
		{
			headers: {
				Accept: 'application/ld+json',
			},
		}
	);

	return {
		items: response['hydra:member'],
		total: response['hydra:totalItems'],
	};
};

export const getImageCategories = async ({ queryKey }) => {
	return await authorizedFetch(
		`${queryKey[0]}?pagination=${false}&exists[parentCategory]=false`
	);
};

export const getPublisher = async ({ queryKey }) => {
	const response = await authorizedFetch(`${queryKey[0]}/${queryKey[1]}`);
	return response;
};

export const getMailQueues = async ({ queryKey }) => {
	return await authorizedFetch(`${queryKey[0]}?pagination=${false}`);
};

export const getImageGroupCategory = async ({ queryKey }) => {
	return await authorizedFetch(`${queryKey[0]}/${queryKey[1]}`);
};

export const getRecentPurchasedItemsByPublisher = async ({ queryKey }) => {
	const response = await authorizedFetch(
		`${queryKey[0]}?publisher=${queryKey[2]}&page=${queryKey[1]}&itemsPerPage=${PER_PAGE}&createdAt[strictly_after]=${queryKey[3]}`,
		{
			headers: {
				Accept: 'application/ld+json',
			},
		}
	);

	return {
		items: response['hydra:member'],
		total: response['hydra:totalItems'],
	};
};

export const getHistoricDues = async ({ queryKey }) => {
	return await authorizedFetch(
		`${queryKey[0]}?publisher=${queryKey[1]}&createdAt[before]=${queryKey[2]}`
	);
};

export const getContents = async ({ queryKey }) => {
	const response = await axios.get(
		`${process.env.REACT_APP_API_URL}/${queryKey[0]}?pagination=${false}`
	);
	return response.data;
};

export const getConversations = async ({ queryKey }) => {
	return await authorizedFetch(
		`${queryKey[0]}?publisherReceiver=${queryKey[1]}&order[createdAt]=desc`
	);
};

export const getConversation = async ({ queryKey }) => {
	return await authorizedFetch(`${queryKey[0]}/${queryKey[1]}`);
};
