import React, { useLayoutEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const RegisterSuccess = () => {
	const [serachparams] = useSearchParams();
	const [confirmationResult, setConfirmationResult] = useState(0);
	const navigation = useNavigate();

	useLayoutEffect(() => {
		const error = !!serachparams.get('api_error');
		const success = !!serachparams.get('api_success');
		if (!error && !success) {
			navigation('/');
			return;
		}
		if (error) {
			setConfirmationResult(1);
		}
		if (success) {
			setConfirmationResult(2);
		}
	}, []);

	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
		>
			<div
				className="mp-flex-wrapper__left mp-flex-white js-mp-flex-white tab active login mp-flex-wrapper__small"
				id="tab1"
			>
				<div className="w-100">
					<h2
						className="mp-flex-white__title"
						style={{ marginBottom: 0, textAlign: 'center' }}
					>
						{confirmationResult === 2 &&
							'Dziękujemy, Twoje konto zostało aktywowane!'}
						{confirmationResult === 1 && 'Aktywacja konta nieudana!'}

						<div className="form__actions mb-20 mt-40">
							<Link to={'/'} className="btn btn--block">
								<div>Przejdź do strony logowania</div>
							</Link>
						</div>
					</h2>
				</div>
			</div>
		</motion.div>
	);
};

export default RegisterSuccess;
